<template>
  <v-container>
    <div class="alert__container">
      <div class="title__container">
        <h2>
          <v-icon color="#3a4f3f">
            mdi-bell-ring
          </v-icon>
          알림 수신 설정
        </h2>
        <h4>문자 알림 수신 여부를 선택합니다.</h4>
      </div>

      <div class="alertset__container">
        <div class="alert_info">
          <div class="alert_set">
            <div class="alert_title">
              내외부 온도 격차 알림
            </div>
            <div class="switch">
              <span class="mt-2 mr-2"
                  :class="!room_outside_yn ? 'color_green' : 'color_gray'"> OFF </span>
              <v-switch
                @change="changeState(room_outside_yn)"
                v-model="room_outside_yn"
                hide-details
                class="mt-0 ml-1"
                color="#3a4f3f"
                inset
              ></v-switch>

              <span class="mt-2" style="margin-left:-5px"
              :class="room_outside_yn ? 'color_green' : 'color_gray'"> ON </span>
            </div>
          </div>
          <div class="sub_info">현재온도가 기준 외부 온도와의 격차가 심할 때</div>
        </div>

        <div class="alert_info">
          <div class="alert_set">
            <div class="alert_title">내부 온도 변화 알림</div>

            <div class="switch">
              <span class="mt-2 mr-2"
                  :class="!room_changeup_yn ? 'color_green' : 'color_gray'"> OFF </span>
              <v-switch
                @change="changeState(room_changeup_yn)"
                v-model="room_changeup_yn"
                hide-details
                class="mt-0 ml-1"
                color="#3a4f3f"
                inset
              ></v-switch>

              <span class="mt-2" style="margin-left:-5px"
              :class="room_changeup_yn ? 'color_green' : 'color_gray'"> ON </span>
            </div>
          </div>
          <div class="sub_info">온도의 급격한 변화 또는 한계 값 이상, 이하일 때</div>
        </div>

        <div class="alert_info">
          <div class="alert_set">
            <div class="alert_title">데이터 수신 불량 알림</div>
            
            <div class="switch">
              <span class="mt-2 mr-2"
                  :class="!data_disable_yn ? 'color_green' : 'color_gray'"> OFF </span>
              <v-switch
                @change="changeState(data_disable_yn)"
                v-model="data_disable_yn"
                hide-details
                class="mt-0 ml-1"
                color="#3a4f3f"
                inset
              ></v-switch>

              <span class="mt-2" style="margin-left:-5px"
              :class="data_disable_yn ? 'color_green' : 'color_gray'"> ON </span>
            </div>
          </div>
          <div class="sub_info">일정시간 이상 데이터 수신이 없을 때</div>
        </div>
      </div>
    </div>
  </v-container>
</template>
<script>
import Apis from '@/api/apis'
var setTime = null;

export default {
  name: "AlertPersonalSet",
  data() {
    return{
      room_outside_yn: false,
      room_changeup_yn: false,
      data_disable_yn: false,
    }
  },
  async created(){
    Apis.pageCount({
      page_name: this.$route.name,
      url: this.$route.path,
      division: "pigro",
    },() => {  // 정상처리
      // console.log("pageCount 호출",res);
    }).catch( (err) => {  // 개별 API 오류 처리 예제
      console.log("pageCount 호출 오류",err);
    });

    await this.infoAlert();
  },
  methods:{
    infoAlert(){
      this.$store.commit("resMessage","");
      // console.log("Switch "+id);
      // console.log(this.$getters.userInfo.account)

      Apis.infoPersonalChoicedAlerts({
        },(res) => {
          if (res.result) {
            // alert(res.message);
            this.$store.commit("resMessage",res.message);
            this.data_disable_yn = res.data.data_disable_yn || false;
            this.room_changeup_yn = res.data.room_changeup_yn || false;
            this.room_outside_yn = res.data.room_outside_yn || false;
          } else {
            console.log("정보 오류",res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("infoPersonalChoicedAlerts API 호출 오류",err)
            alert(err);
        })
    },
    changeState() {
      clearTimeout(setTime);
      setTime = setTimeout( () =>  this.saveState(), 2 * 1000);   // 여러함목 변경대비 2초 대기후 서버적용
    },

    saveState() {
      this.$store.commit("resMessage","");
      
      Apis.savePersonalChoicedAlerts({
        "room_outside_yn": this.room_outside_yn,
        "room_changeup_yn": this.room_changeup_yn,
        "data_disable_yn": this.data_disable_yn
        },(res) => {
          if (res.result) {
            // alert(res.message);
            this.$store.commit("resMessage",res.message);
          } else {
            console.log("저장 오류",res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("savePersonalChoicedAlerts API 호출 오류",err)
            alert(err);
        })
    }
  }
}
</script>

<style scoped>
.alert__container{
  margin:15px;
}
.title__container{
  margin-left:5px;
  margin-bottom:20px;
  letter-spacing: -0.75px;
  color:#3a4f3f;
}

.alertset__container{
  padding:5px;
}
.alert_info{
  margin-bottom:20px;
  height:20%;
  padding:10px 10px 20px 10px;
  border:1px solid #3a4f3f;
  border-radius:20px;
  background-color:#fff7de;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
}

.alert_title{
  font-size:1.2rem;
  font-weight:700;
  color:#3a4f3f;
}
.alert_set{
  display:flex;
  justify-content: space-between;
  align-items: center;
  height:50px;
  margin-bottom:5px;
}
.switch{
  display:flex;
  font-size:13px;
}
.sub_info{
  font-size:1.0rem;
  letter-spacing: -1px;
  margin-left:10px;
  line-height:50p
}
.color_green{
  color:#88191a;
  font-weight: 600;
  font-size:14px;
}
.color_gray{
  color:#ababab;
}
.shortcut_icon{
  width:18px;
  height:18px;
  margin-right:3px;
  filter: invert(23%) sepia(6%) saturate(2314%) hue-rotate(82deg) brightness(98%) contrast(78%);
}
</style>